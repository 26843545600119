import React from 'react';
// @ts-ignore
import coach1 from "../../../assets/img/coaches/seoPagesCoach1.png";
// @ts-ignore
import coach2 from "../../../assets/img/coaches/seoPagesCoach2.png";
// @ts-ignore
import coach3 from "../../../assets/img/coaches/seoPagesCoach3.png";
// @ts-ignore
import frFlag from "../../../assets/img/france.png";

const Coaches = () => {
    const members = [
        {
            name: 'Thomas Bétrémieux',
            location: 'Paris, France',
            img: coach2,
            flag: frFlag
        },
        {
            name: 'Olivier Friedman',
            location: 'Toulouse, France',
            img: coach1,
            flag: frFlag
        },
        {
            name: 'Marine Villalon',
            location: 'Lyon, France',
            img: coach3,
            flag: frFlag
        }
    ];

    return (

        <div className="section text-center bg-white py-20">
            <h2 className="text-2xl font-bold mb-2">Urna nulla justo blandit</h2>
            <p className="text-gray-500 mb-10">Tortor a leo magnis consequat vestibulum.</p>
            <div className="grid md:grid-cols-3 gap-20">
                {members.map((member, index) => (
                    <div key={index} className=" bg-warm text-center aspect-square flex flex-col items-center justify-center">
                        <img src={member.img} className="w-full h-full mb-4" />
                        <h3 className="text-lg font-semibold mt-1">{member.name}</h3>
                        <div className="flex justify-center items-center text-gray-600 mb-4">
                            <img src={member.flag} alt="flag" className="w-5 h-5 mr-2 rounded-full" />
                            <span>{member.location}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>


    );
};

export default Coaches;
