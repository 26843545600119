import React from 'react'
// @ts-ignore
import Station from '../../../assets/img/seo/Station.png'
// @ts-ignore
import Decideurs from '../../../assets/img/seo/Decideurs.png'

const Highlights = () => {
    return (
        <div className="flex bg-white py-20 px-8 gap-20">
            <div className="flex-1  bg-warm shadow-md rounded-xl p-8 text-center">
                <img
                    src={Station}
                    alt="Station F Logo"
                    className="mx-auto mb-4 w-32 h-32"
                />
                <p className="text-gray-600">Backed by Slack "Future of Work" Program Station F</p>
            </div>

            <div className="flex-1 bg-warm shadow-md rounded-xl p-8 text-center">
                <img
                    src={Decideurs}
                    alt="Décideurs Logo"
                    className="mx-auto mb-4 w-32 h-32"
                />
                <p className="text-gray-600">Ranked "Excellent" by Décideurs Magazine in 2023 leaders tables of best coaching firms.</p>
            </div>

            {/* Third Card */}
            <div className="flex-1 bg-warm shadow-md rounded-xl p-8 text-center">
                <h3 className="text-5xl font-bold mt-8 h-32 w-32">4.9/5</h3>
                <p className="text-gray-600 -mt-3">Average satisfaction out of 2409 coaching sessions</p>
            </div>
        </div>
    )
}

export default Highlights