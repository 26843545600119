import { graphql } from "gatsby";
import React from "react";
import Intro from "./sections/Intro";
import Why from "./sections/Why";
import Trusted from "./sections/Trusted";
import Wall from "./sections/Wall";
import FAQ from "./sections/FAQ";
import City from "./sections/City";
import Prices from "./sections/Prices";
import Highlights from "./sections/Highlights";
import Footer from "../../sections/Footer";
import Coaches from "./sections/Coaches";
import InquiryForm from "./sections/Inquire";

const CityPage = (data: any) => {
    const cityData = data?.data.prismicSeoEnPage?.dataRaw;
    const city = data?.data.prismicSeoEnPage?.uid;
    console.log(data, "cityData");

    const FAQData = [
        {
            title: "What criteria should you use to choose your professional coach?\n",
            content: (
                <>
                    <p>
                        {cityData.what_criteria_should_you_use_to_choose_your_professional_coach[0].text}
                    </p>
                </>
            ),
        },
        {
            title: "What is the right price for a professional coach?\n",
            content: (
                <>
                    <p>
                        {cityData.what_is_the_right_price_for_a_professional_coach[0].text}
                    </p>
                </>
            ),
        },
        {
            title: "How many sessions do I need?\n",
            content: (
                <>
                    <p>
                        {cityData.how_many_sessions_do_i_need[0].text}
                    </p>
                </>
            ),
        },
    ]

    return (
        <div>
            <Intro city={city.replace(/\b\w/g, (char: string) => char.toUpperCase())} />
            <Why />
            <Trusted />
            <Wall />
            <Coaches />
            <City
                city={city.replace(/\b\w/g, (char: string) => char.toUpperCase())}
                information_city_website={cityData.information_city_website[0].text}
                coaching_market_website={cityData.coaching_market_website[0].text}
            />
            <Prices
                city={city.replace(/\b\w/g, (char: string) => char.toUpperCase())}
                individual_coaching={cityData.individual_coaching[0].text}
                group_coaching={cityData.group_coaching[0].text}
                executive_coaching={cityData.executive_coaching[0].text}
            />
            <FAQ FAQData={FAQData} city={city.replace(/\b\w/g, (char: string) => char.toUpperCase())} />
            <Highlights />
            <InquiryForm />
            <Footer />
        </div>
    );
};

export default CityPage;

export const query = graphql`
query CityPage($uid: String) {
    prismicSeoEnPage(uid: { eq: $uid }) {
        id
        uid
        dataRaw
    }
}`;
