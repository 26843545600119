import React from "react";
import Accordion from "../../../components/old/Accordion/Accordion";

interface FAQItem {
    title: string;
    content: JSX.Element;
}

interface FaqProps {
    FAQData: FAQItem[];
    city: any;
}

const FAQ: React.FC<FaqProps> = ({ FAQData, city }) => {
    return (
        <div className="section bg-warm py-10">
            <div className="max-w-4xl mx-auto"> {/* Centers the FAQ container */}
                <div className="text-center">
                    <h2 className="text-2xl font-bold mb-12">How to Pick Your Coach in {city}?</h2>
                </div>
                <div className="text-justify px-16">
                    <div className="flex flex-col gap-5">
                        {FAQData.map(({ title, content }, index) => (
                            <Accordion key={index} title={title} content={content} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQ;
