import React from 'react'
// @ts-ignore
import headerIcon from "../../../assets/img/old/header.svg";
import Img from "../../../components/Img";
// @ts-ignore
import microsoftLogo from "../../../assets/img/companies/microsoft.png";
// @ts-ignore
import qontoLogo from "../../../assets/img/companies/qontoLogo.png";
// @ts-ignore
import hecLogo from "../../../assets/img/companies/HEC.png";

interface IntroProps {
    city: string;
}

const Intro: React.FC<IntroProps> = ({ city }) => {
    return (
        <div>
            <div className="section bg-warm py-5 flex justify-center items-center">
                <Img
                    className="max-sm:!h-8 align-center"
                    triggerPx={640}
                    srcSmall={headerIcon}
                    srcLarge={headerIcon}
                />
            </div>
            <div className="section bg-warm py-10 flex flex-col items-center justify-center">
                <h1 className="leading-[1.1] my-3 text-center">
                    Are you looking for the best professional coaches in {city}?
                </h1>

                <p
                    style={{
                        maxWidth: "80%",
                        fontSize: 20,
                        fontWeight: "bold",
                        color: "#4F4F4F",
                        textAlign: "center",
                    }}
                >
                    Look no further - receive coaching by those doing it for leaders at
                </p>
                <div className="p-5 flex flex-wrap justify-center [&>div>img]:h-12">
                    <div>
                        <img src={microsoftLogo} />
                    </div>
                    <div>
                        <img src={qontoLogo} />
                    </div>
                    <div>
                        <img src={hecLogo} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Intro