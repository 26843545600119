import React from 'react'
import "../SEO.css"

interface PricesProps {
    city: string;
    individual_coaching: string;
    group_coaching: string;
    executive_coaching: string;
}

const Prices: React.FC<PricesProps> = ({ city, individual_coaching, group_coaching, executive_coaching }) => {
    return (
        <div className="bg-white py-20 px-24">
            <h2 className="text-2xl font-bold text-center mb-6">How much does coaching cost in {city}?</h2>
            <div className="table-container">
                <div className="table">
                    <table className='w-full mx-10 border-collapse'>
                        <thead className="table-header bg-warm">
                            <tr>
                                <th className="border border-gray-300 p-4">Type of coaching</th>
                                <th className="border border-gray-300 p-4">{city}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="table-row">
                                <td className="border border-gray-300 bg-warm p-4">Individual Coaching</td>
                                <td className="border border-gray-300 bg-warm font-bold p-4">{individual_coaching}</td>
                            </tr>
                            <tr className="table-row">
                                <td className="border border-gray-300 bg-warm p-4">Group Coaching</td>
                                <td className="border border-gray-300 bg-warm font-bold p-4">{group_coaching}</td>
                            </tr>
                            <tr className="table-row">
                                <td className="border border-gray-300 bg-warm p-4">Executive Coaching</td>
                                <td className="border border-gray-300 bg-warm font-bold p-4">{executive_coaching}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default Prices;
