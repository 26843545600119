import React from 'react'
// @ts-ignore
import CityImg from "../../../assets/img/seo/City.png";

interface CityProps {
    city: string;
    information_city_website: string;
    coaching_market_website: string;
}

const City: React.FC<CityProps> = ({ city, information_city_website, coaching_market_website }) => {
    return (
        <div className='flex bg-warm p-5'>
            <div className="flex-1 px-5 items-center">
                <div className=" p-10 rounded-lg shadow-md max-w-md w-full text-center">
                    <h2 className="text-2xl font-bold mb-6">{city}</h2>
                    <div className="p-8 mx-5 rounded-xl mt-6 bg-white">
                        <img src={CityImg} alt="Paris" className="h-32 w-32" />
                        <p className="mt-5 text-gray-700 text-justify">{information_city_website}</p>
                    </div>
                </div>
            </div>

            <div className="flex-1 px-5 items-center">
                <div className=" p-10 rounded-xl shadow-md max-w-md w-full text-center">
                    <h2 className="text-2xl font-bold mb-6">{city} coaching market</h2>
                    <div className="p-8 rounded-xl mx-5 mt-6 bg-white">
                        <img src={CityImg} alt="Paris Coaching Market" className="h-32 w-32" />
                        <p className="mt-5 text-gray-700 text-justify">{coaching_market_website}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default City